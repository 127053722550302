<template>
  <v-theme-provider dark>
    <base-section
      id="contact-us"
      class="primary"
      space="36"
    >
      <v-row
        align="center"
        class="ma-0"
        justify="center"
      >
        <base-title
          space="0"
          :title="$t('sections.newsletter.title')"
        />

        <v-responsive
          class="px-4 mx-6"
          max-width="600"
          width="100%"
        >
          <base-text-field
            v-model="emailNl"
            :rules="[rules.required, rules.email]"
            hide-details="auto"
            :label="$t('sections.newsletter.placeholder')"
          >
            <template #append-outer>
              <v-btn
                class="ml-n1"
                height="40"
                outlined
                style="margin-top: -7px;"
                :disabled="!(email && emailFormat)"
                @click="subscribe($event)"
              >
                {{ $t('sections.newsletter.cta') }}
              </v-btn>
            </template>
          </base-text-field>
        </v-responsive>
      </v-row>
    </base-section>
  </v-theme-provider>
</template>

<script>
  export default {
    name: 'SectionNewsletterAlt',
    data: () => ({
      email: false,
      emailFormat: false,
      emailNl: '',
    }),
    computed: {
      rules () {
        return {
          required: value => {
            if (!value) {
              this.email = false
            } else {
              this.email = true
            }
            return !!value || this.$t('common.validation.required')
          },
          counter: value => {
            return value.length <= 20 || this.$t('common.validation.maxchar.' + 20)
          },
          email: value => {
            const pattern = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/
            if (pattern.test(value)) {
              this.emailFormat = true
              return true
            } else {
              this.emailFormat = false
              return this.$t('common.validation.email')
            }
          },
        }
      },
    },
    methods: {
      subscribe (event) {
        console.log(event)
        this.$store.dispatch('emails/subscribe', this.emailNl).then(
          (data) => {
            console.log('response', data)
            var message = ''
            var error
            if (data && data.success) {
              message = (data.optedIn ? this.$t('sections.newsletter.subscription.optedIn') : this.$t('sections.newsletter.subscription.success'))
              error = false
            } else {
              message = this.$t('sections.newsletter.subscription.error')
              error = true
            }
            console.log('This', this)
            this.$notify({
              message: message,
              icon: 'mdi mdi-bell',
              horizontalAlign: 'center',
              verticalAlign: 'top',
              type: error ? 'danger' : 'success',
            })
          },
          (error) => {
            this.message =
              (error.response &&
                error.response.data &&
                error.response.data.message) ||
              error.message ||
              error.toString()
          },
        )
      },
    },
  }
</script>
